@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap);
body {
  margin: 0;
}

#wrapper {
  height: 100vh;
}

form {
  font-size: 1.1em;
  padding: 4px;
}

.url {
  width: 400px;
}

body {
  font-family: 'Fira Mono', monospace;
}

label {
  background:#eee;
  padding: 5px;
  border: 1px solid #292e36;
  margin: 2px;
}

.topBar .title span {
  display: none;
}


/* Overload Graphiql Variables */

.graphiql-container .topBar,
.editor-drag-bar,
.CodeMirror-gutters *,
.secondary-editor-title *,
.variable-editor-title *,
.graphiql-container .secondary-editor-title,
.graphiql-container .variable-editor-title-text.active,
.graphiql-container .historyShow,
graphiql-explorer-root *,
.graphiql-container .docExplorerShow
{
  background: #000;
  color: #eee;
}

.graphiql-container .CodeMirror {
  font-family: 'JetBrains Mono', monospace;
}

.graphiql-container .execute-button {
  /* background: radial-gradient(75.29% 75.29% at 64.96% 24.36%, rgb(71, 88, 241) 14.06%, rgb(94, 92, 210) 57.81%, rgb(70, 185, 220) 99.48%); */
  background: radial-gradient(75.29% 75.29% at 64.96% 24.36%,
      #dcc8d0 15.62%,
      #78c8cf 30.21%,
      #4d959e 42.71%,
      #305eb9 55.73%,
      #311f12 79.69%,
      #684232 90.62%,
      #2d1c13 100%);
  color: #f8f8f0;
  box-shadow: none;
}

/* Explorer */

.graphiql-explorer-root path {
  fill: #eee
}


/* Documentation Explorer */
.docExplorerWrap .doc-explorer-title-bar,
.docExplorerWrap .doc-explorer-contents,
.docExplorerWrap .doc-explorer-contents * {
  background: #000;
}

.graphiql-container .field-short-description, .doc-type-description{
  color: #f8f8f0;
}

.search-box-icon {
  color: #f8f8f0;
}

.graphiql-container,
.graphiql-container button,
.graphiql-container input {
  color: #f8f8f0;
}

/* Play Button */
path {
  stroke: rgb(0, 0, 0);
  stroke-width: 1px;
  fill: rgb(0, 0, 0);
}

.execute-button * {
  color: #f8f8f0;
}

.graphiql-container .toolbar-button {
  background: #eee;
  color: #000;
  box-shadow: none;
  border-radius: 1px;
}


/* NOTE: this applies just to the editor box theme

    Name:       material
    Author:     Michael Kaminsky (http://github.com/mkaminsky11)

    Original material color scheme by Mattia Astorino (https://github.com/equinusocio/material-theme)

*/

.cm-s-material.CodeMirror {
  background-color: #000;
  color: rgba(233, 237, 237, 1);
}

.cm-s-material .CodeMirror-gutters {
  background: #000;
  color: rgb(83, 127, 126);
  border: none;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: rgb(83, 127, 126);
}

.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #f8f8f0;
}

.cm-s-material div.CodeMirror-selected {
  background: rgba(255, 255, 255, 0.15);
}

.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(255, 255, 255, 0.10);
}

.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line>span::selection,
.cm-s-material .CodeMirror-line>span>span::selection {
  background: rgba(255, 255, 255, 0.10);
}

.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line>span::-moz-selection,
.cm-s-material .CodeMirror-line>span>span::-moz-selection {
  background: rgba(255, 255, 255, 0.10);
}

.cm-s-material .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0);
}

.cm-s-material .cm-keyword {
  color: rgba(199, 146, 234, 1);
}

.cm-s-material .cm-operator {
  color: rgba(233, 237, 237, 1);
}

.cm-s-material .cm-variable-2 {
  color: #80CBC4;
}

.cm-s-material .cm-variable-3 {
  color: #82B1FF;
}

.cm-s-material .cm-builtin {
  color: #DECB6B;
}

.cm-s-material .cm-atom {
  color: #F77669;
}

.cm-s-material .cm-number {
  color: #F77669;
}

.cm-s-material .cm-def {
  color: rgba(233, 237, 237, 1);
}

.cm-s-material .cm-string {
  color: #C3E88D;
}

.cm-s-material .cm-string-2 {
  color: #80CBC4;
}

.cm-s-material .cm-comment {
  color: #546E7A;
}

.cm-s-material .cm-variable {
  color: #82B1FF;
}

.cm-s-material .cm-tag {
  color: #80CBC4;
}

.cm-s-material .cm-meta {
  color: #80CBC4;
}

.cm-s-material .cm-attribute {
  color: #FFCB6B;
}

.cm-s-material .cm-property {
  color: #80CBAE;
}

.cm-s-material .cm-qualifier {
  color: #DECB6B;
}

.cm-s-material .cm-variable-3 {
  color: #DECB6B;
}

.cm-s-material .cm-tag {
  color: rgba(255, 83, 112, 1);
}

.cm-s-material .cm-error {
  color: rgba(255, 255, 255, 1.0);
  background-color: #EC5F67;
}

.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

